<template>
  <q-card>
    <q-card-section>
      <q-form
        ref="form"
        class="q-gutter-md"
      >
        <div class="row justify-between">
          <div class="col-12">
            <q-input
              v-model="payload.title"
              type="text"
              label="Title"
              dense
              lazy-rules
              :rules="[(val) => $rules('Title', val).required]"
            />
          </div>
          <div class="col-12">
            <q-input
              v-model="payload.description"
              type="textarea"
              label="Description"
              dense
              lazy-rules
              :rules="[(val) => $rules('Description', val).required]"
            />
          </div>
        </div>
        <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-model="payload.companyName"
              type="text"
              label="Company Name"
              dense
              lazy-rules
              :rules="[(val) => $rules('Company Name', val).required]"
            />
          </div>
          <div class="col-5">
            <q-input
              v-model="payload.location"
              type="text"
              label="Location"
              dense
              lazy-rules
              :rules="[(val) => $rules('Location', val).required]"
            />
          </div>
        </div>
        <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-model="selectedDate.start"
              type="text"
              label="Start"
              dense
              lazy-rules
              mask="####/##"
              readonly
              :rules="[(val) => $rules('Start', val).required]"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="payload.start"
                      default-view="Months"
                      mask="YYYY-MM-DDTHH:mm:ssZ"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-5">
            <q-input
              v-model="selectedDate.end"
              type="text"
              label="End"
              dense
              lazy-rules
              mask="####/##"
              readonly
              :disable="payload.isCurrent"
              :rules="[(val) => $rules('End', val).required]"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="payload.end"
                      default-view="Months"
                      mask="YYYY-MM-DDTHH:mm:ssZ"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-5">
            <q-checkbox
              v-model="payload.isCurrent"
              label="Current"
              dense
              size="sm"
            />
          </div>
        </div>
      </q-form>
    </q-card-section>

    <q-separator />

    <q-card-actions justify="space-around">
      <div class="col text-right">
        <q-btn-group>
          <q-btn outline size="sm" color="accent" @click="cancel">Cancel</q-btn>
          <q-btn outline size="sm" color="primary" @click="submit">{{ isEditMode ? 'Update' : 'Save' }}</q-btn>
        </q-btn-group>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, getCurrentInstance, onMounted, watch, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, ctx) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const payload = ref({
      location: '',
      end: '',
      isCurrent: false,
      description: '',
      title: '',
      companyName: '',
      start: ''
    })

    const selectedDate = ref({
      start: '',
      end: ''
    })

    const isEditMode = computed(() => props.mode.toLowerCase() === 'edit')

    onMounted(() => {
      if (isEditMode) {
        selectedDate.value.start = props.data?.start
        selectedDate.value.end = props.data?.end
      }
    })

    watchEffect(() => {
      if (isEditMode) {
        payload.value = JSON.parse(JSON.stringify(props.data))
        payload.value.isCurrent = Boolean(props.data?.isCurrent)
      }
    })

    watch(payload.value, (n, o) => {
      selectedDate.value.start = payload.value.start
      selectedDate.value.end = payload.value.end
    })

    const cancel = () => {
      ctx.emit('onCancel')
    }

    const submit = async () => {
      const valid = await instance?.refs.form.validate()

      if (!valid) return

      let res
      if (isEditMode.value) {
        res = await self.$api.work.update(props.data?.id, payload.value)
      } else {
        if (!payload.value.end) {
          payload.value.end = payload.value.start
        }
        res = await self.$api.work.create(payload.value)
      }

      if (res?.success) {
        store.dispatch('setProfileFull')
      }

      ctx.emit('postSubmit')
    }

    return {
      payload,
      submit,
      cancel,
      isEditMode,
      selectedDate
    }
  }
}
</script>
