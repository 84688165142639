<template>
  <div class="row q-pt-lg q-px-lg">
    <!-- Notif Dialog -->
    <q-dialog
      v-model="dialog.show"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div>{{ dialog.message }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat size="sm" label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <!-- Dialog Confirm -->
    <dialog-confirm v-model="dialogConfirm.show" :title="dialogConfirm.title" :body="dialogConfirm.body" @onYes="onDelete" />
    <!-- Form Dialog -->
    <q-dialog
      v-model="dialogForm.show"
    >
      <form-work
        :mode="dialogForm.mode"
        :data="dialogForm.data"
        @onCancel="dialogForm.show = false"
        @postSubmit="dialogForm.show = false"
      />
    </q-dialog>
    <!-- Data Table -->
    <q-table
      class="sticky-action full-width"
      title="Works"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      row-key="id"
      v-model:expanded="expanded"
    >
      <template #top-left>
        <q-btn color="primary" size="sm" @click="onCreate">New</q-btn>
        <span class="text-h6 q-ml-sm">Works</span>
      </template>
      <template #top-right>
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template #body="props">
        <q-tr :props="props">
          <template
            v-for="col in props.cols"
          >
            <q-td
              v-if="col.name === 'action'"
              :key="col.name"
              auto-width
              :props="props"
            >
              <q-btn-group>
                <q-btn color="secondary" size="xs" round icon="edit" @click="onSelectRow('edit', props.row)">
                  <q-tooltip>
                    Edit
                  </q-tooltip>
                </q-btn>
                <q-btn color="red" size="xs" round icon="delete" @click="onSelectRow('delete', props.row)">
                  <q-tooltip>
                    Delete
                  </q-tooltip>
                </q-btn>
                <q-btn color="accent" size="xs" round :icon="props.expand ? 'visibility_off' : 'visibility'" :to="`/works/${props.row.id}/details`">
                  <q-tooltip>
                    Detail
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </q-td>
            <q-td
              v-else
              :key="col.name"
              :props="props"
            >
              {{ col.value }}
            </q-td>
          </template>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, getCurrentInstance } from '@vue/runtime-core'
import { formatDate, limitText } from '../utils/functions'
import formWork from '../components/formWork.vue'

export default {
  components: { formWork },
  setup () {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const dialog = ref({
      show: false,
      message: ''
    })

    const dialogForm = ref({
      show: false,
      mode: 'new',
      data: ref({})
    })

    const dialogConfirm = ref({
      show: false,
      title: 'Wait',
      body: 'This can\'t be undone! Proceed?'
    })

    const filter = ref('')

    const columns = ref([
      { name: 'action', label: '', align: 'left', field: '' },
      { name: 'title', label: 'Title', align: 'left', field: 'title', sortable: true },
      { name: 'description', label: 'Description', align: 'left', field: 'description', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'companyName', label: 'Company', align: 'left', field: 'companyName', sortable: true },
      { name: 'isCurrent', label: 'Current', field: 'isCurrent', sortable: false, format: val => val ? 'Yes' : 'No' },
      { name: 'location', label: 'Location', field: 'location', sortable: true },
      { name: 'start', label: 'Start', field: 'start', sortable: true, format: val => formatDate(val, 'YYYY, MMM DD') },
      { name: 'end', label: 'End', field: 'end', sortable: true, format: val => formatDate(val, 'YYYY, MMM DD') },
      { name: 'createdAt', label: 'Created At', field: 'createdAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') },
      { name: 'updatedAt', label: 'Updated At', field: 'updatedAt', sortable: true, format: val => formatDate(val, 'DD-MMM-YYYY HH:mm') }
    ])

    const expanded = ref([])

    const rows = computed(() => store.state.works)

    const expandOnly = (rowId) => {
      expanded.value = [rowId]
    }

    const selectedRow = ref({})

    const onSelectRow = async (mode, selected) => {
      selectedRow.value = selected
      if (mode === 'edit') {
        dialogForm.value.show = true
        dialogForm.value.mode = mode
        dialogForm.value.data = selected
      } else {
        dialogConfirm.value.show = true
      }
    }

    const onDelete = async () => {
      await self.$api.work.delete(selectedRow.value?.id)
      await store.dispatch('setProfileFull')
      dialogConfirm.value.show = false
      dialog.value.show = true
      dialog.value.message = 'Delete work success'
    }

    const onCreate = () => {
      dialogForm.value.show = true
      dialogForm.value.mode = 'new'
      dialogForm.value.data = {
        location: '',
        end: '',
        isCurrent: false,
        description: '',
        title: '',
        companyName: '',
        start: ''
      }
    }

    return {
      columns,
      rows,
      onSelectRow,
      dialog,
      filter,
      dialogForm,
      onCreate,
      expanded,
      expandOnly,
      dialogConfirm,
      onDelete
    }
  }
}
</script>
